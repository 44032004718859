import { useEffect, useState, useRef } from "react";
import SignaturePad from 'react-signature-pad-wrapper'
import { useTranslation } from 'react-i18next';


function Unterschrift() {
    const { t } = useTranslation();
    const signaturePadRef = useRef(null);
    const signaturePadRefClient = useRef(null);

    //Display: signaturePadRefClient.current.fromDataURL(res.data?.sign?.client_sign);
    //Save Request: formData.client_sign = signaturePadRefClient.current.toDataURL('image/png')


    return (
        <>
            <div>
                <div className="sign-pad-new">
                    <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)', backgroundColor: '#b9d1ff5e' }} ref={signaturePadRefClient} />
                    <p style={{ borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px' }}>Unterschrift</p>
                </div>
                <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                    <i className="fa fa-eraser"></i>
                </button>

                <button type="button" className="btn btn-primary ms-3">{t('Save')}</button>
            </div>
        </>
    );
}

export default Unterschrift;
